var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"mdi mdi-chevron-left"}),_vm._v(" Kembali ")]),(_vm.isLoading)?void 0:(_vm.error)?[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("Danger Alert")])]:[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body"},[(!_vm.isLoading && _vm.result)?_c('div',{staticClass:"d-flex mb-9"},[_c('div',{staticClass:"flex-grow-1"},[_vm._m(0),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex bg-secondary rounded my-2",staticStyle:{"height":"50px","width":"50px"}}),_c('div',{staticClass:"pt-4"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.result.student_id.full_name)+" ")]),_c('br'),_c('span',{staticClass:"card-label font-weight-bolder text-muted"},[_vm._v(" "+_vm._s(_vm.result.student_id.no_induk)+" ")])])]),_c('div',{staticClass:"d-flex flex-wrap justify-content-between mt-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 pr-8"},[_c('div',{staticClass:"d-flex flex-wrap mb-1"},[_c('a',{staticClass:"text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"flaticon-clock-1 mr-2 font-size-lg"}),_vm._v(" "+_vm._s(_vm.result.remaining_time > 0 ? _vm.result.exam_id.time - _vm.result.remaining_time : "-")+" Menit ")]),_c('a',{staticClass:"text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"flaticon2-calendar-2 mr-2 font-size-lg"}),_vm._v(" "+_vm._s(_vm.result.remaining_time > 0 ? _vm.result.updatedAt : "-")+" ")])])])]),_c('div',{staticClass:"d-flex mt-5",class:{
                'justify-content-center': _vm.result.ices,
              },staticStyle:{"gap":"1rem"}},[(_vm.result.ices)?_vm._l((Object.keys(_vm.result.ices)),function(item){return _c('b-alert',{key:item,attrs:{"show":"","variant":"info"}},[_vm._v(" "+_vm._s(item.replaceAll("_", " "))+" : "+_vm._s(_vm.result.ices[item])+" % ")])}):[_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Score "+_vm._s(parseFloat(_vm.result.scores).toFixed(2))+" ")])]],2)])]):(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")]),_vm._v(" Sedang Memuat Lembar Jawaban... ")],1)],1):_vm._e()])]),(!_vm.isLoading && _vm.result.answers.length > 0)?_c('b-row',{staticClass:"h-100 mt-10"},[_c('b-col',{attrs:{"xs":"12","lg":"9","md":"12"}},[_c('div',{class:`card card-custom card-stretch gutter-b`},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column justify-content-center"},[_c('span',{staticClass:"card-label font-weight-bolder text-muted my-2"}),_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Soal "+_vm._s(_vm.selectedIndex + 1)+" ")])])]),_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1 pb-5"},[_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.selectedQuestion.question)}}),_c('div',{staticClass:"jawaban"},[(_vm.selectedQuestion.type != 'essay')?_vm._l((_vm.selectedQuestion.exam_answers),function(jawaban,i){return _c('div',{key:`${_vm.selectedQuestion._id}${i}`,class:`card card-custom card-stretch border ${
                      jawaban.point > 0
                        ? 'bg-light-primary text-primary border-primary'
                        : ''
                    }`},[_c('div',{staticClass:"card-body d-flex flex-row p-4 align-items-center"},[(_vm.selectedQuestion.type == 'multiple answer')?_c('b-form-checkbox',{staticClass:"align-items-start cursor-pointer",attrs:{"disabled":"","name":`jawaban${_vm.selectedQuestion._id}`,"checked":_vm.selectedQuestion.student_answer.filter(
                            (item) => item._id == jawaban._id
                          ).length > 0
                            ? true
                            : false,"size":"lg"}}):_c('b-form-radio',{staticClass:"align-self-start cursor-pointer",attrs:{"disabled":"","name":`jawaban${_vm.selectedQuestion._id}`,"value":jawaban._id,"size":"lg"},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}}),_c('div',{domProps:{"innerHTML":_vm._s(jawaban.answer)}})],1)])}):_vm._e(),(_vm.selectedQuestion.type == 'essay')?_c('b-form-group',{attrs:{"label":"Jawaban"}},[_c('b-form-textarea',{attrs:{"id":`textarea-jawaban-${_vm.i}`,"rows":"3","disabled":""},on:{"input":_vm.persist},model:{value:(_vm.selectedQuestion.student_answer),callback:function ($$v) {_vm.$set(_vm.selectedQuestion, "student_answer", $$v)},expression:"selectedQuestion.student_answer"}})],1):_vm._e()],2)]),_c('div',[_c('b-row',{staticClass:"justify-content-between"},[_c('b-col',[(_vm.previousVisible)?_c('b-button',{on:{"click":_vm.previous}},[_vm._v(" previous ")]):_vm._e()],1),_c('b-col',{staticClass:"row justify-content-end"},[(_vm.nextVisible)?_c('b-button',{on:{"click":_vm.next}},[_vm._v(" next ")]):_vm._e()],1)],1)],1)])])]),_c('b-col',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.questions),function(soal,i){return _c('b-col',{key:soal._id,staticClass:"m-2 text-center",attrs:{"cols":"2"}},[_vm._v("   "),_c('span',{class:`btn btn-icon btn-sm symbol ${
                    _vm.selectedQuestion._id == soal._id
                      ? 'btn-light-warning active'
                      : (
                          soal.type == 'essay'
                            ? soal.student_answer !== undefined
                              ? soal.student_answer.length > 0
                              : false
                            : soal.student_answer.length > 0
                        )
                      ? soal.flagged
                        ? 'btn-light-info active'
                        : 'btn-light-primary' + ' active'
                      : soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-secondary'
                  }`,on:{"click":function($event){return _vm.changeSoal(i)}}},[_c('span',{class:`svg-icon svg-icon-md ${
                      soal.flagged ? 'svg-icon-light' : ''
                    }`},[_vm._v(" "+_vm._s(i + 1)+" ")])])])}),1)],1)])])],1):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fa fa-file text-white mb-3",staticStyle:{"font-size":"4rem"}}),_c('br'),_vm._v(" Pegawai Belum Mengerjakan Ujian ")])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between flex-wrap mt-1"},[_c('div',{staticClass:"d-flex flex-column mr-3"},[_c('a',{staticClass:"text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3",attrs:{"href":"#"}},[_vm._v(" Lembar Jawaban ")])])])
}]

export { render, staticRenderFns }