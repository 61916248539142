<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>

    <template v-if="isLoading"> </template>
    <template v-else-if="error">
      <b-alert show variant="danger">Danger Alert</b-alert>
    </template>

    <template v-else>
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div class="d-flex mb-9" v-if="!isLoading && result">
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex flex-column mr-3">
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >
                    Lembar Jawaban
                  </a>
                </div>
              </div>

              <div class="d-flex" style="gap: 1rem">
                <div
                  class="d-flex bg-secondary rounded my-2"
                  style="height: 50px; width: 50px"
                ></div>
                <div class="pt-4">
                  <span class="card-label font-weight-bolder text-dark">
                    {{ result.student_id.full_name }}
                  </span>
                  <br />
                  <span class="card-label font-weight-bolder text-muted">
                    {{ result.student_id.no_induk }}
                  </span>
                </div>
              </div>

              <div class="d-flex flex-wrap justify-content-between mt-1">
                <div class="d-flex flex-column flex-grow-1 pr-8">
                  <div class="d-flex flex-wrap mb-1">
                    <a
                      href="#"
                      class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    >
                      <i class="flaticon-clock-1 mr-2 font-size-lg"></i>

                      {{
                        result.remaining_time > 0
                          ? result.exam_id.time - result.remaining_time
                          : "-"
                      }}
                      Menit
                    </a>
                    <a
                      href="#"
                      class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      ><i class="flaticon2-calendar-2 mr-2 font-size-lg"></i>
                      {{ result.remaining_time > 0 ? result.updatedAt : "-" }}
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="d-flex mt-5"
                :class="{
                  'justify-content-center': result.ices,
                }"
                style="gap: 1rem"
              >
                <template v-if="result.ices">
                  <b-alert
                    show
                    variant="info"
                    v-for="item in Object.keys(result.ices)"
                    :key="item"
                  >
                    {{ item.replaceAll("_", " ") }} : {{ result.ices[item] }} %
                  </b-alert>
                </template>
                <template v-else>
                  <b-alert show variant="info">
                    Score {{ parseFloat(result.scores).toFixed(2) }}
                  </b-alert>
                </template>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center" v-else-if="isLoading">
            <b-button variant="primary" disabled>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
              Sedang Memuat Lembar Jawaban...
            </b-button>
          </div>
        </div>
      </div>
      <b-row class="h-100 mt-10" v-if="!isLoading && result.answers.length > 0">
        <b-col xs="12" lg="9" md="12">
          <div :class="`card card-custom card-stretch gutter-b`">
            <div class="card-header border-0 py-5">
              <h3
                class="card-title align-items-start flex-column justify-content-center"
              >
                <span class="card-label font-weight-bolder text-muted my-2">
                </span>
                <span class="card-label font-weight-bolder text-dark">
                  Soal {{ selectedIndex + 1 }}
                </span>
              </h3>
            </div>
            <div class="card-body d-flex flex-column">
              <div class="flex-grow-1 pb-5">
                <div v-html="selectedQuestion.question" class="mb-4"></div>

                <div class="jawaban">
                  <template v-if="selectedQuestion.type != 'essay'">
                    <div
                      :class="`card card-custom card-stretch border ${
                        jawaban.point > 0
                          ? 'bg-light-primary text-primary border-primary'
                          : ''
                      }`"
                      v-for="(jawaban, i) in selectedQuestion.exam_answers"
                      :key="`${selectedQuestion._id}${i}`"
                    >
                      <div
                        class="card-body d-flex flex-row p-4 align-items-center"
                      >
                        <b-form-checkbox
                          disabled
                          :name="`jawaban${selectedQuestion._id}`"
                          :checked="
                            selectedQuestion.student_answer.filter(
                              (item) => item._id == jawaban._id
                            ).length > 0
                              ? true
                              : false
                          "
                          size="lg"
                          class="align-items-start cursor-pointer"
                          v-if="selectedQuestion.type == 'multiple answer'"
                        ></b-form-checkbox>
                        <b-form-radio
                          disabled
                          :name="`jawaban${selectedQuestion._id}`"
                          :value="jawaban._id"
                          v-model="selectedRadio"
                          size="lg"
                          class="align-self-start cursor-pointer"
                          v-else
                        ></b-form-radio>
                        <div v-html="jawaban.answer"></div>
                      </div>
                    </div>
                  </template>
                  <b-form-group
                    v-if="selectedQuestion.type == 'essay'"
                    label="Jawaban"
                  >
                    <b-form-textarea
                      :id="`textarea-jawaban-${i}`"
                      v-model="selectedQuestion.student_answer"
                      rows="3"
                      @input="persist"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div>
                <b-row class="justify-content-between">
                  <b-col>
                    <b-button v-if="previousVisible" @click="previous">
                      previous
                    </b-button>
                  </b-col>
                  <b-col class="row justify-content-end">
                    <b-button v-if="nextVisible" @click="next"> next </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 py-5">
              <b-row class="justify-content-center">
                <b-col
                  cols="2"
                  class="m-2 text-center"
                  v-for="(soal, i) in questions"
                  :key="soal._id"
                >
                  &nbsp;
                  <span
                    @click="changeSoal(i)"
                    :class="`btn btn-icon btn-sm symbol ${
                      selectedQuestion._id == soal._id
                        ? 'btn-light-warning active'
                        : (
                            soal.type == 'essay'
                              ? soal.student_answer !== undefined
                                ? soal.student_answer.length > 0
                                : false
                              : soal.student_answer.length > 0
                          )
                        ? soal.flagged
                          ? 'btn-light-info active'
                          : 'btn-light-primary' + ' active'
                        : soal.flagged
                        ? 'btn-light-info active'
                        : 'btn-secondary'
                    }`"
                  >
                    <span
                      :class="`svg-icon svg-icon-md ${
                        soal.flagged ? 'svg-icon-light' : ''
                      }`"
                    >
                      {{ i + 1 }}
                    </span>
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-alert class="text-center" show variant="danger" v-else>
        <i class="fa fa-file text-white mb-3" style="font-size: 4rem" />
        <br />
        Pegawai Belum Mengerjakan Ujian
      </b-alert>
    </template>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { SIDE_BAR } from "@/core/services/store/htmlclass.module";

import {
  EXAM_START,
  STUDENT_RESULT_EXAM,
} from "@/core/services/store/ujian.module";

import { mapGetters, mapState } from "vuex";
import {
  GRADE_ATTEMPT,
  SET_ONE_ATTEMPT,
  SET_STATE_ATTEMPT,
  QUESTION,
} from "@/core/services/store/attempt.module";
import Swal from "sweetalert2";
import router from "../../../router";

// import {UPDATE_ATTEMPT} from "@/core/services/store/attempt.module";

export default {
  name: "ReviewExam",
  created() {},
  watch: {
    selectedIndex: function (newVal) {
      const maxSize = this.questions.length - 1;

      if (newVal == 0) {
        this.previousVisible = false;
      } else {
        this.previousVisible = true;
      }

      if (newVal == maxSize) {
        this.nextVisible = false;
      } else {
        this.nextVisible = true;
      }

      this.selectedQuestion = this.questions[newVal];

      if (
        this.selectedQuestion.type == "bool" ||
        this.selectedQuestion.type == "multiple choice"
      ) {
        let studentAnswer = this.selectedQuestion.student_answer;
        if (studentAnswer.length > 0) {
          this.selectedRadio = studentAnswer[0]._id;
        }
      }
    },
    selectedQuestion: function () {
      if (this.selectedQuestion.type == "multiple choice") {
        if (this.selectedQuestion.student_answer.length > 0) {
          this.selectedQuestion.id_jawaban =
            this.selectedQuestion.student_answer[0]._id;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Review Exam" }]);
    var body = KTUtil.getById("kt_wrapper");

    // this.questions = this.currentAttempt.question_pack_id.questions;
    // this.selectedQuestion = this.questions[this.selectedIndex];

    if (this.questions.length > 0) {
      this.nextVisible = true;
    }

    this.getData();
  },
  destroyed() {
    var body = KTUtil.getById("kt_wrapper");
    KTUtil.addClass(body, "wrapper");
    clearInterval(this.interval);
    this.$store.dispatch(SIDE_BAR, true);
    this.$store.commit(SET_STATE_ATTEMPT, { attempting: false });
    this.$store.dispatch(EXAM_START, false);
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      interval: null,
      selectedIndex: -1,
      sliding: null,
      questions: [],
      selectedQuestion: {},
      nextVisible: false,
      previousVisible: false,
      exam_type: {
        default: "default",
        ices: "ices",
      },
      isLoading: true,
      result: null,
      error: null,
      selectedRadio: "",
    };
  },
  computed: {
    employeeId() {
      return this.$route.params.employeeId;
    },
    examId() {
      return this.$route.params.examId;
    },
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    persist() {
      // this.$store.commit(SET_ONE_ATTEMPT, this.currentAttempt);
    },
    addToAnswer(jawaban, answer) {
      const isExist = jawaban.filter((item) => item == answer).length > 0;
      if (isExist) {
        jawaban.remo;
      }

      if (jawaban.includes(answer)) {
        jawaban.splice(jawaban.indexOf(answer), 1);
      } else {
        jawaban.push(answer);
      }
    },
    submitUjian() {},
    penilaian(elapsed) {
      const overlay = this.$loadingOverlay.show();

      var question = [];
      this.questions.forEach((value, index) => {
        var myAnswer = [];
        var item = {};
        if (value.type != "essay") {
          value.answer
            .filter((item) => item.selected == true)
            .forEach((item) => {
              myAnswer.push(item._id);
            });

          item = {
            _id: value._id,
            answer: myAnswer,
          };
        } else {
          item = {
            _id: value._id,
            answer: value.jawaban,
          };
        }

        question.push(item);
      });

      var _data = {};

      // _data._id = this.currentAttempt._id;
      _data.body = {
        remaining_time: `${elapsed}`,
        answer: question,
      };

      this.$store
        .dispatch(GRADE_ATTEMPT, _data)
        .then(() => {
          overlay.hide();
          Swal.fire({
            title: "",
            text: "Submit ujian berhasil!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.replace("ujian");
        })
        .catch(() => {
          overlay.hide();

          Swal.fire({
            title: "",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((value) => {
            if (value) {
              this.$router.push("/ujian");
            }
          });
        });
    },
    next() {
      this.selectedIndex++;
    },
    previous() {
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      }
    },
    changeJawaban(item, soal) {
      if (soal.type == "multiple answer") {
        this.$set(item, "selected", !item.selected);
      } else {
        soal.answer.forEach((jawab) => {
          this.$set(jawab, "selected", false);
        });
        this.$set(soal, "id_jawaban", item._id);
        this.$set(item, "selected", true);
      }
    },
    scrollToSoal(i) {
      const el = this.$el.getElementsByClassName(`soal-${i}`)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    changeSoal(i) {
      this.selectedIndex = i;
    },
    confirmationBack() {
      this.$bvModal
        .msgBoxConfirm(
          "Apakah anda yakin akan kembali ke halaman sebelumnya?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$router.push("/ujian");
          }
        });
    },
    getData() {
      const params = {
        employee_id: this.employeeId,
        exam_id: this.examId,
      };

      this.error = null;
      this.isLoading = true;
      this.$store
        .dispatch(STUDENT_RESULT_EXAM, params)
        .then((res) => {
          this.result = res.data;
          this.questions = res.data.answers;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: `Review ${this.result.exam_id.name}` },
          ]);

          this.selectedIndex = 0;

          setTimeout(() => {
            this.selectedQuestion = this.questions[this.selectedIndex];
          }, 500);
        })
        .catch((e) => {
          this.error = e;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
